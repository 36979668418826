<template>
  <div class="row">
    <div class="col-lg-7 col-xl-8">
      <figure
        class="extra-rounded background-image position-relative mb-0"
        v-if="venue"
        :style="{ backgroundImage: `url('${venue.headline_image}')` }"
      >
        <figcaption class="position-absolute header-caption text-white">
          {{ venue.name }}
          <div class="header-city">{{ city.name }}</div>
        </figcaption>
      </figure>
      <div class="pl-xl-4 pt-4">
        <section class="d-none d-lg-block" v-if="venue">
          <div class="d-flex">
            <div class="font-weight-bold mr-5">
              <div class="text-secondary">Dress Code</div>
              <div class="text-white" v-if="venue">{{ venue.dress_code }}</div>
            </div>
            <div class="font-weight-bold mr-5 pl-5">
              <div class="text-secondary">Opening Times</div>
              <div class="text-white text-lowercase" v-if="venue">{{ venueOpeningHours }}</div>
            </div>
            <div class="font-weight-bold pl-5">
              <div class="text-secondary">Entry ages</div>
              <div class="text-white" v-if="venue">{{ venue.pop_age_min }}+</div>
            </div>
          </div>
          <div class="mt-5">
            <div class="text-secondary font-weight-bold">Club Information</div>
            <div class="text-white" v-if="venue">{{ venue.description }}</div>
          </div>
        </section>
        <upcoming-events
          :venue="venue"
          :events="venue.venue_events"
          v-if="venue && venue.venue_events.length > 0"
        />
      </div>
    </div>
    <div class="col-lg-5 col-xl-4 mt-4 mt-lg-0">
      <venue-calendar v-if="venue" />
      <buy-tickets classes="mt-4" v-if="selectedDateHasTickets" />
      <book-tables classes="mt-4" v-if="selectedDateHasTables" />
    </div>
    <div class="col-12 mt-4 d-lg-none font-weight-bold">
      <div class="text-secondary">Dress Code</div>
      <div class="text-white mb-4" v-if="venue">{{ venue.dress_code }}</div>
      <div class="text-secondary">Opening Times</div>
      <div class="text-white text-lowercase mb-4" v-if="venue">{{ venueOpeningHours }}</div>
      <div class="text-secondary">Entry ages</div>
      <div class="text-white mb-4" v-if="venue">{{ venue.pop_age_min }}+</div>
      <div class="text-secondary">Club Information</div>
      <div class="text-white font-weight-normal" v-if="venue">{{ venue.description }}</div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import VenueCalendar from '../components/VenueCalendar'
import BuyTickets from '../components/BuyTickets'
import BookTables from '../components/BookTables'
import UpcomingEvents from '../components/UpcomingEvents'
import { addMonths } from 'date-fns'

export default {
  components: {
    VenueCalendar,
    BuyTickets,
    BookTables,
    UpcomingEvents
  },

  computed: {
    ...mapGetters([
      'venue',
      'city',
      'selectedDateHasTickets',
      'selectedDateHasTables',
      'venueOpeningHours'
    ])
  },

  mounted() {
    this.setCurrentVenue(this.$route.params.venue)
    this.getVenue().then(() => {
      if(this.$route.query.rep != undefined){
				this.trackClick(this.$route.query.rep);
			}
      this.getOpeningHours()
      this.getCity()
      this.getCalendarDays(new Date()).then(() =>
        this.getAvailabilityForSelectedDate()
      )
      this.getCalendarDays(addMonths(new Date(), 1))
    })
  },

  methods: {
    ...mapActions([
      'getVenue',
      'getOpeningHours',
      'getCity',
      'getCalendarDays',
      'getAvailabilityForSelectedDate',
			'trackClick'
    ]),
    ...mapMutations(['setCurrentVenue'])
  }
}
</script>
