<template>
  <div class="col-6 col-sm-4 text-center text-white mb-4 mb-lg-5">
    <article class="event">
      <router-link :to="`/venues/${venue.slug}/events/${event.id}`">
        <div class="event-image w-100" :style="{ 'background-image': `url('${event.image}')` }"></div>
        <h2 class="mt-2 mb-0 event-name text-white">{{ event.name }}</h2>
        <time class="event-date text-white">{{ formatEventDate(event.date) }}</time>
      </router-link>
    </article>
  </div>
</template>

<script>
import { format } from 'date-fns'

export default {
  props: {
    venue: Object,
    event: Object
  },

  methods: {
    formatEventDate(eventDate) {
      return format(new Date(eventDate), 'dd MMM')
    }
  }
}
</script>