<template>
  <section class="mt-4 pt-3 mt-md-5">
    <div class="d-flex">
      <img src="../assets/ticket.svg" alt="Upcoming Events" class="img-fluid" />
      <h2 class="heading text-secondary ml-3 mb-0 font-weight-bold">Upcoming Events</h2>
    </div>
    <section class="mt-4">
      <div class="row">
        <upcoming-event v-for="event in events" :key="event.id" :venue="venue" :event="event" />
      </div>
    </section>
  </section>
</template>

<script>
import UpcomingEvent from './UpcomingEvent'

export default {
  props: {
    venue: Object,
    events: Array
  },

  components: {
    UpcomingEvent
  }
}
</script>