<template>
	<section class="d-flex justify-content-center justify-content-xl-end">
		<vuejs-datepicker
			:inline="true"
			:full-month-name="true"
			:monday-first="true"
			:disabled-dates="disabledDates"
			:value="selectedDate"
			:highlighted="highlightedCalendarDays"
			@selected="selectDate"
			@changedMonth="nextMonth"
		/>
	</section>
</template>

<script>
	import vuejsDatepicker from 'vuejs-datepicker'
	import {mapState, mapActions, mapGetters} from 'vuex'
	import {subDays} from 'date-fns'

	export default {
		components: {
			vuejsDatepicker
		},

		computed: {
			...mapState(['selectedDate']),
			...mapGetters(['highlightedCalendarDays'])
		},

		data() {
			return {
				disabledDates: {
					to: subDays(new Date(), 1)
				}
			}
		},

		methods: {
			...mapActions(['nextMonth', 'selectDate'])
		}
	}
</script>
